// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/slogan.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/slogan.tsx");
  import.meta.hot.lastModified = "1710146880033.8704";
}
// REMIX HMR END

export function Slogan() {
  return <p className="leading-tight max-2xl:!fs-heading">
			<span className="block line-clamp-1 relative">
				<strong className="transition duration-300 group-hover:translate-y-[-100%] text-white font-bold line-clamp-1">
					Shockingly
				</strong>
				<strong className="transition duration-300 group-hover:translate-y-[-100%] text-white font-bold line-clamp-1 absolute top-[100%] left-0 right-0">
					Shockingly
				</strong>
			</span>
			<span className="block line-clamp-1 relative">
				<span className="transition duration-[400ms] group-hover:translate-y-[-100%] text-black line-clamp-1">
					Straightforward
				</span>
				<span className="transition duration-[400ms] group-hover:translate-y-[-100%] text-black line-clamp-1 absolute top-[100%] left-0 right-0">
					Straightforward
				</span>
			</span>
		</p>;
}
_c = Slogan;
var _c;
$RefreshReg$(_c, "Slogan");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;