// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/video.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/video.tsx");
  import.meta.hot.lastModified = "1710147301897.8416";
}
// REMIX HMR END

import { useState } from 'react';
export function Video() {
  _s();
  const [play, setPlay] = useState(false);
  if (play) {
    return <div className="w-full h-full bg-[black] relative group">
				<video key="full-reel" className="w-full h-full object-cover rounded-2xl overflow-hidden" autoPlay playsInline controls onEnded={() => {
        window.metricalp.event({
          type: 'video_end'
        });
        setPlay(false);
      }}>
					<source src="/selestial-full-reel-v1.mp4" type="video/mp4" />
				</video>
			</div>;
  }
  return <div className="w-full h-full bg-[#eae0d1] relative group">
			<video key="intro" className="w-full h-full pointer-events-none object-cover rounded-2xl overflow-hidden" autoPlay muted loop playsInline>
				<source src="/selestial-reel-intro-v1.mp4" type="video/mp4" />
			</video>

			<button onClick={() => {
      setPlay(true);
      window.metricalp.event({
        type: 'video_play'
      });
    }} className="absolute inset-0 flex justify-center items-center mx-auto opacity-80">
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 123 140" className="max-h-[30%] max-w-[30%] w-full h-full object-contain group-hover:scale-95 transition">
					<path fill="#fff" d="M120.5 65.67c3.333 1.924 3.333 6.736 0 8.66L8 139.282c-3.333 1.925-7.5-.481-7.5-4.33V5.048C.5 1.2 4.667-1.207 8 .718L120.5 65.67Z" />
				</svg>
			</button>
		</div>;
}
_s(Video, "RwyEwJduY3JPWFOsoAJvrD+HutA=");
_c = Video;
var _c;
$RefreshReg$(_c, "Video");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;