// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/clock.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/clock.tsx");
  import.meta.hot.lastModified = "1709050243526.0747";
}
// REMIX HMR END

import { useEffect, useState } from 'react';
export function Clock() {
  _s();
  const [time, setTime] = useState();
  useEffect(() => {
    setTime(new Date());
    const timerId = setInterval(() => {
      setTime(new Date());
    }, 1000);
    return () => clearInterval(timerId);
  }, []);
  if (!time) {
    return null;
  }
  return <div className="clock">
			<div className="hour_hand" style={{
      transform: `rotateZ(${time.getHours() * 30}deg)`
    }} />
			<div className="min_hand" style={{
      transform: `rotateZ(${time.getMinutes() * 6}deg)`
    }} />
			<div className="sec_hand" style={{
      transform: `rotateZ(${time.getSeconds() * 6}deg)`
    }} />
		</div>;
}
_s(Clock, "rkMeZBHX4Eei19CRvWL8taVSCjQ=");
_c = Clock;
var _c;
$RefreshReg$(_c, "Clock");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;