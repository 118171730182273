// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/slider.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/slider.tsx");
  import.meta.hot.lastModified = "1709564311912.5293";
}
// REMIX HMR END

import { Children, useEffect, useState } from 'react';
export function Slider({
  children,
  btnClassName,
  delay = 5000,
  onActiveChange
}) {
  _s();
  const [active, setActive] = useState(0);
  const [mouseIn, setMouseIn] = useState(false);
  const len = Children.count(children);
  useEffect(() => {
    if (mouseIn) {
      return;
    }
    const timeout = setTimeout(() => {
      setActive((active + 1) % len);
    }, delay);
    return () => {
      clearTimeout(timeout);
    };
  }, [active, mouseIn, len, delay]);
  useEffect(() => {
    if (onActiveChange) {
      onActiveChange(active);
    }
  }, [active, onActiveChange]);
  return <div className="group w-full h-full overflow-hidden relative" onMouseEnter={() => setMouseIn(true)} onMouseLeave={() => setMouseIn(false)}>
			<div
    // style={{ y: `-${active * 100}%` }}
    className="w-full h-full *:w-full *:h-full transition-transform duration-[450ms]" style={{
      transform: `translateY(-${active * 100}%)`
    }}>
				{children}
			</div>
			<div className="absolute right-[10px] top-0 bottom-0 !w-auto !h-full">
				<div className="flex flex-col justify-center h-full">
					{Array.from({
          length: len
        }).map((_, i) => <button key={i} data-current={active} data-active={i === active} onClick={() => setActive(i)} className={`w-[7px] h-[7px] rounded-full my-1 opacity-20 transition data-[active=true]:opacity-100 ` + (btnClassName ?? '')} />)}
				</div>
			</div>
		</div>;
}
_s(Slider, "BPVLDQDzjtGb69y/FSnbQsOT9vY=");
_c = Slider;
var _c;
$RefreshReg$(_c, "Slider");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;